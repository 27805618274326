import { PreReleaseSponsor, WhatsInside } from "@/shared/models";
import classNames from "classnames";
import Image from "next/image";
import React, { FC, useState } from "react";
import Button from "./Button";
import WhatsInsideItem from "./WhatsInsideItem";
import Router from "next/router";
import Input from "./Input";
import { DiscountCodeAttributes } from "@/shared/models/strapi";
import { useLoginContext } from "../redux/selectors";
interface Props {
  whatsInside: WhatsInside[] | [];
  className?: string;
  isPreview?: boolean;
  actionHref?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: (e: any) => void;
  actionText?: string;
  title?: string;
  price?: number;
  actionDisabled?: boolean;
  showTitle?: boolean;
  showPayment?: boolean;
  disabled?: boolean;
  sponsor?: PreReleaseSponsor | null;
  showDiscountCode?: boolean;
  onSubmitCode?: (value: string) => void;
  discountedPrice?: number;
  discountObject?: DiscountCodeAttributes | null;
  children?: any;
}

const WhatsInsideComponent: FC<Props> = ({
  whatsInside,
  className,
  isPreview,
  actionHref,
  actionText,
  title = "What's Inside",
  price,
  actionDisabled = false,
  action,
  showTitle = true,
  showPayment = false,
  disabled = false,
  sponsor,
  showDiscountCode,
  discountObject,
  onSubmitCode,
  discountedPrice,
  children,
}) => {
  const { loginContext } = useLoginContext();
  const [discountCode, setDiscountCode] = useState("");

  const handleButton = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    if (actionHref) {
      Router.push(actionHref ?? "");
    } else {
      if (action) {
        action(e);
      }
    }
  };

  const hasChildren = () => {
    if (children.length > 0) {
      let has = false;
      children.forEach((element: any) => {
        if (element) {
          has = true;
        }
      });

      return has;
    }
    return false;
  };

  const goToSponsor = () => {
    window.open(sponsor?.link, "_blank");
  };

  const _class = classNames(
    `flex flex-col flex-1 space-y-3 p-6 rounded-sm text- bg-gray-card h-fit font-poppins text-gray-800 ${
      isPreview && `border-2 border-[#282829] ${disabled && "text-[#5e5e5e]"}`
    }`,
    className
  );
  return (
    <section>
      <div className={_class}>
        <div className="flex flex-col space-y-2">
          {showTitle && (
            <h6 className="text-[18px] leading-tight font-medium ">{title}</h6>
          )}
          {sponsor && (
            <div className="flex items-center gap-2  text-[0.9rem] pb-2 border-b border-gray-450">
              <p>Brought to you by</p>
              <button onClick={goToSponsor}>
                <Image
                  src={sponsor.logo}
                  width={80}
                  height={40}
                  alt="sponsor"
                  className="cursor-pointer object-cover"
                />
              </button>
            </div>
          )}
          <div className="h-full flex flex-col space-y-3 pb-3">
            {whatsInside.map((item, index) => {
              return (
                <WhatsInsideItem
                  disabled={disabled}
                  whatsInside={item}
                  key={index}
                />
              );
            })}
          </div>
        </div>
        {whatsInside.length > 0 && isPreview && (
          <div className="h-[2px] bg-gray-450"></div>
        )}
        {isPreview && (
          <>
            {children && hasChildren() ? (
              <div className="flex flex-col space-y-4 items-end md:flex-row md:justify-between md:items-center px-2">
                {children}
              </div>
            ) : (
              <div className="flex flex-col space-y-4 items-end md:flex-row md:justify-between md:items-center pt-4 px-2">
                <div className="flex w-full md:w-fit items-center space-x-6">
                  {price && (
                    <div className="text-[24px] text-brand font-bold ">
                      {discountedPrice ? (
                        <div className="flex flex-col md:flex-row items-center space-x-2">
                          <p className="line-through text-gray-350">${price}</p>
                          <p className="">${discountedPrice}</p>
                        </div>
                      ) : (
                        <p>${price}</p>
                      )}
                    </div>
                  )}
                  <Button
                    onClick={handleButton}
                    primary
                    disabled={actionDisabled || disabled}
                    className="w-full md:w-[149px] text-[14px] h-[44px] px-10 py-4 rounded-[8px] font-bold"
                  >
                    {actionText}
                  </Button>
                </div>

                {showPayment && (
                  <div className="flex gap-2">
                    <Image
                      src="/static/icons/metamask.svg"
                      width={24}
                      height={24}
                      alt="metamask"
                    />
                    <Image
                      className="object-contain"
                      src="/static/icons/mastercard.svg"
                      width={38}
                      height={12}
                      alt="mastercard"
                    />
                    <Image
                      src="/static/icons/visa.svg"
                      className="object-contain"
                      width={38}
                      height={32}
                      alt="visa"
                    />
                  </div>
                )}
              </div>
            )}
            {loginContext.user && showDiscountCode && !discountedPrice && (
              <div className="h-[2px] bg-gray-450"></div>
            )}
            {discountedPrice && discountObject && (
              <div>
                <p className="text-xxs md:text-xs">
                  You used <b className="text-brand">{discountObject.code}</b>{" "}
                  code for{" "}
                  <b className="text-brand">{discountObject.percentatge}%</b>{" "}
                  Off!
                </p>
              </div>
            )}
            {loginContext.user && showDiscountCode && !discountedPrice && (
              <div className="pt-4  text-gray-300 flex flex-col items-start space-y-2 md:flex-row md:justify-between md:items-center">
                <p>Have a discount code?</p>
                <div className="flex">
                  <Input
                    className="md:w-[200px] h-[40px]"
                    name="discountCode"
                    type="text"
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                    placeholder="COCACOLA"
                  />
                  <Button
                    className="w-[75px] text-[0.8rem] h-[35px] font-bold hover:text-brand"
                    secondary
                    disabled={!onSubmitCode}
                    onClick={() => onSubmitCode && onSubmitCode(discountCode)}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default WhatsInsideComponent;
