import React, { FC } from "react";

interface Props {
  text: string | null;
}

const Markup: FC<Props> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <div className="kl-Markup" dangerouslySetInnerHTML={{ __html: text }} />
  );
};

export default Markup;
