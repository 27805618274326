import { WhatsInside } from "@/shared/models";
import Image from "next/image";
import { FC, useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import Markup from "./Markup";

interface ItemProps {
  whatsInside: WhatsInside;
  disabled?: boolean;
}

const WhatsInsideItem: FC<ItemProps> = ({ whatsInside, disabled = false }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [openInfo, setOpenInfo] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (event) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setOpenInfo(false);
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <div>
      <article className="flex py-2 text-xs justify-between space-x-4 items-center">
        <div className="relative flex w-full items-center justify-between">
          <div className="flex space-x-4 items-center">
            <Image
              src={whatsInside.icon}
              alt={`${whatsInside.text} image`}
              height={22}
              width={22}
              objectFit="cover"
              className={`rounded-full object-cover ${
                disabled && "opacity-20"
              }`}
            />

            <span className="font-inter leading-tight font-medium">
              {whatsInside.text}
            </span>
          </div>
          {whatsInside.description && !disabled && (
            <button onClick={() => setOpenInfo(true)} className="z-1">
              <Image
                className={`${disabled && "opacity-20"}`}
                alt={`${whatsInside.text}-desc`}
                src="/static/icons/add.svg"
                width="24"
                height="24"
              />
            </button>
          )}
          {openInfo && (
            <div
              ref={ref}
              className="flex flex-row items-start justify-between absolute z-10  rounded-[16px] bg-[#16171a] pt-3 pb-5 px-4 w-[275px] md:w-[325px] -right-4 -top-1/2"
            >
              <Markup text={whatsInside.description} />
              <button>
                <MdClose onClick={() => setOpenInfo(false)} size={20} />
              </button>
            </div>
          )}
        </div>
      </article>
    </div>
  );
};

export default WhatsInsideItem;
