import { useEffect, useState } from "react";

const twBreakpoints = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
};

type Breakpoint = keyof typeof twBreakpoints;

// Uses tailwinds breakpoints to consume media queries.
const useMediaQuery = (twBreakpoint: Breakpoint) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const query = `(min-width: ${twBreakpoints[twBreakpoint]})`;
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const resizeListener = () => {
      setMatches(media.matches);
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [matches, twBreakpoint]);

  return matches;
};

export default useMediaQuery;
