import { Artist, Nft, PreRelease } from "@/shared/models";
import React, { FC, useRef } from "react";
import { GetServerSideProps } from "next";
import Hero from "@/client/components/Hero";
import { findAllArtists } from "@/backend/repositories/artist-repo";
import { findAllNfts } from "@/backend/repositories/nft-repo";
import FixedContainer from "@/client/layouts/FixedContainer";
import { withConnection } from "@/backend/utils/database";
import { staleWhileRevalidate } from "@/backend/responses/cache-control";
import { NextSeo } from "next-seo";
import Image from "next/image";
import { enrichNfts } from "@/backend/services/strapi/utils/nfts";
import { findAllPreReleases } from "@/backend/repositories/pre-release-repo";
import { enrichPreReleases } from "@/backend/services/strapi/utils/preRelease";
import { sortNfts } from "@/shared/utils/sort";
import { ArtistLatestItem } from "@/client/components/ArtistLatestItem";
import Link from "next/link";
import { getArtistUrl } from "@/shared/utils/createUrls";
import useMediaQuery from "@/client/hook/useMediaQuery";
import { getExtraKloovs } from "@/shared/utils/getExtraKloovs";
import { getHomeInfo } from "@/backend/services/strapi/utils/static";
import { HomeAttributes } from "@/shared/models/strapi";
import Markup from "@/client/components/Markup";
import Verified from "@/client/components/icons/Verified";

interface Props {
  nfts: (Nft | PreRelease)[];
  highlightedItem: Nft | PreRelease | null;
  artists: Artist[];
  extraKloovs: number;
  homeContent: HomeAttributes;
}

const Home: FC<Props> = ({
  nfts,
  artists,
  highlightedItem,
  extraKloovs,
  homeContent,
}) => {
  const matches = useMediaQuery("md");
  const nftRef = useRef<HTMLDivElement>(null);

  const extraKloovCards = [];
  const ExtraKloov = (e: number) => {
    return (
      <Link key={e} href="/new-kloov" passHref>
        <div className="flex flex-col items-center justify-center  space-y-2 border-dashed border-[2px] border-[#5c5c5c] bg-[#2b2c30f2] w-[120px] md:w-[180px] h-[120px] md:h-[180px] px-7 md:py-10 rounded-full cursor-pointer ">
          <Image
            className="rounded-full object-contain  w-[100px] md:w-[120px] h-[100px]"
            src="/static/icons/kloov_fire.svg"
            alt={`${e}-fire`}
            width={matches ? 120 : 100}
            height={matches ? 120 : 100}
          />
          <div className="flex gap-2 items-start text-center text-[#a3a3a3] text-xs md:text-md">
            Start a new Kloov
          </div>
        </div>
      </Link>
    );
  };
  for (let i = 0; i < extraKloovs; i++) {
    extraKloovCards.push(ExtraKloov(i));
  }

  return (
    <>
      <NextSeo
        robotsProps={{
          noarchive: true,
        }}
      />
      {/* head */}

      <Hero nftRef={nftRef} homeContent={homeContent} />

      <FixedContainer className=" text-white font-poppins flex flex-col  items-center justify-center mt-5 space-y-40">
        {/** Recent Kloovs */}
        <div className="z-10 flex flex-col items-center justify-center space-y-10 max-w-[700px]">
          <p className="text-center font-black text-[32px] md:text-[40px]">
            Recent Kloovs
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 place-items-center gap-7 md:gap-[4.75rem] max-w-[700px]">
            {artists.map((a) => {
              return (
                <Link
                  key={a.id}
                  href={getArtistUrl(a.username)}
                  passHref={true}
                >
                  <div className="cursor-pointer flex flex-col items-center justify-center  space-y-2">
                    <Image
                      className="rounded-full  object-cover"
                      src={a.avatar}
                      alt={`${a.id}-avatar`}
                      width={matches ? 180 : 100}
                      height={matches ? 180 : 100}
                    />
                    <div className="flex gap-2 justify-center items-start">
                      <p className="text-[12px] md:text-[16px] font-extrabold">
                        {a.name}
                      </p>
                      {a.claimed && <Verified size={12} />}
                    </div>
                  </div>
                </Link>
              );
            })}
            {extraKloovCards}
          </div>
        </div>

        {/** STATIC TEXT */}
        <Markup text={homeContent.kloovs_info} />

        {nfts.length > 0 && highlightedItem && (
          <ArtistLatestItem
            isHomePage={true}
            title={"Previously on Kloov"}
            latestItem={highlightedItem}
            className="md:mb-24"
            showBackground={false}
          />
        )}

        {/**Contact us */}
      </FixedContainer>
    </>
  );
};

export const getServerSideProps = withConnection<GetServerSideProps>()(
  async (connection, { res }) => {
    const dbArtists = await findAllArtists(connection, 50);
    const nfts = await findAllNfts(connection, 50);
    const preReleases = await findAllPreReleases(connection, 50);

    //staleWhileRevalidate(res);

    const items = [
      ...(await enrichNfts(connection, nfts)),
      ...(await enrichPreReleases(connection, preReleases)),
    ].sort(sortNfts);

    const highlightedItem = items.find(
      (n: Nft | PreRelease) => n.isHighlighted === true
    );

    const homeContent = await getHomeInfo(dbArtists);

    const extraKloovs = getExtraKloovs(
      homeContent.enrichedArtists?.length ?? 0
    );

    return {
      props: {
        artists: homeContent.enrichedArtists ?? [],
        highlightedItem: highlightedItem ?? null,
        nfts: items,
        extraKloovs: extraKloovs,
        homeContent,
      },
    };
  }
);

export default Home;
