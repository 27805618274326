import { HomeAttributes } from "@/shared/models/strapi";
import classNames from "classnames";
import Link from "next/link";
import { FC } from "react";
import FixedContainer from "../layouts/FixedContainer";
import Button from "./Button";
import Markup from "./Markup";

interface Props {
  nftRef: React.RefObject<HTMLDivElement>;
  homeContent: HomeAttributes;
  className?: string;
}

const Hero: FC<Props> = ({ className, homeContent }) => {
  const _class = classNames(
    "bg-city-pattern  bg-cover bg-no-repeat",
    className
  );
  return (
    <section
      className={_class}
      style={{
        backgroundPositionY: 0,
        backgroundPositionX: "center",
      }}
    >
      <FixedContainer className="flex flex-col items-center justify-between gap-2 md:gap-9 pt-40 md:pt-0 md:justify-evenly  w-full h-[550px] md:h-[690px] md:w-auto">
        <div className="w-[98.5vw]  mt-20 md:mt-[20rem] text-center font-poppins text-white  h-[60px] md:h-[150px] text-[24px] md:text-[48px] font-black leading-tight">
          <Markup text={homeContent.slogan} />
        </div>
        <div className=" py-10 md:py-0">
          <Link href="/signup" passHref={true}>
            <Button
              primary
              className="hover:bg-[#222222] w-[149px] h-[44px] text-center rounded-[8px] text-[14px] font-extrabold"
            >
              Join KLOOV
            </Button>
          </Link>
        </div>
      </FixedContainer>
    </section>
  );
};

export default Hero;
