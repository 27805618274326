import { Nft, NftType, PreRelease } from "@/shared/models";
import { getAvantsUrl, getNftUrl } from "@/shared/utils/createUrls";
import { stripHtmlFromString } from "@/shared/utils/markup";
import classNames from "classnames";
import Image from "next/image";
import React, { FC } from "react";
import Markup from "./Markup";
import NftCard from "./NftCard";
import PatternBackground from "./PatternBackground";
import PreReleaseCard from "./PreReleaseCard";
import WhatsInsideComponent from "./WhatsInsideComponent";

interface Props {
  latestItem: Nft | PreRelease;
  className?: string;
  title?: string;
  isHomePage?: boolean;
  showBackground?: boolean;
}
export const ArtistLatestItem: FC<Props> = ({
  title,
  latestItem,
  className,
  isHomePage = false,
  showBackground = true,
}) => {
  const isNft = "type" in latestItem;

  const getNftType = (type: NftType) => {
    switch (type) {
      case NftType.FULLTRACK:
        return "Xclusiv";
      case NftType.KLIP:
        return "Klip";
      default:
        return "";
    }
  };
  const _class = classNames(
    "relative z-10 flex flex-col items-center justify-start w-full space-y-10 text-white font-poppins",
    className
  );

  const nftType = isNft
    ? getNftType(latestItem?.type ?? NftType.FULLTRACK)
    : "Avants";
  const nftUrl = isNft
    ? getNftUrl(latestItem as Nft)
    : getAvantsUrl(latestItem as PreRelease);

  return (
    latestItem && (
      <div className={_class}>
        <div className="flex gap-3 text-[24px] md:text-[48px]  font-extrabold">
          {title ? (
            <p>{title}</p>
          ) : (
            <>
              <p>Official {nftType}</p>
              <Image
                src="/static/icons/icon_tick.svg"
                alt="verify"
                objectFit="contain"
                width={12}
                height={12}
              />
            </>
          )}
        </div>
        {/** LARGE SCREEN */}
        <div className="hidden md:flex flex-row items justify-center space-x-20 mx-20">
          <div>
            {isNft ? (
              <NftCard
                artistPage={!isHomePage}
                artistPreview={true}
                nft={latestItem}
              />
            ) : (
              <PreReleaseCard
                artistPage={!isHomePage}
                artistPreview={true}
                preRelease={latestItem}
              />
            )}
          </div>
          <div className="flex flex-col space-y-7 flex-1 max-w-[636px] mx-20 ">
            <div className="flex flex-col space-y-4">
              <div className="text-[32px] font-black">{latestItem.name}</div>

              <p className=" text-[14px] text-gray-800 tracking-tight leading-5 font-normal  text-justify">
                <Markup text={latestItem.description.substring(0, 220)} />
                ...
                <a href={nftUrl} className="text-brand cursor-pointer">
                  View More
                </a>{" "}
              </p>
            </div>

            <div>
              <WhatsInsideComponent
                disabled={isHomePage}
                isPreview={true}
                sponsor={
                  !isNft
                    ? latestItem.poweredBy
                      ? latestItem.poweredBy
                      : null
                    : null
                }
                className="w-full"
                actionText="Enter"
                whatsInside={latestItem.whatsInside}
                actionHref={nftUrl}
              />
            </div>
          </div>
        </div>
        {showBackground && <PatternBackground className="hidden md:flex" />}
        {/** Small Screen */}
        <div className="flex md:hidden flex-col space-y-5 justify-center items-center mx-20">
          {isNft ? (
            <NftCard
              artistPage={true}
              artistPreview={!isHomePage}
              nft={latestItem}
            />
          ) : (
            <PreReleaseCard
              artistPage={true}
              artistPreview={!isHomePage}
              preRelease={latestItem}
            />
          )}
          <div className="flex flex-col space-y-7 flex-1 ">
            <div className="flex flex-col space-y-4">
              <div className="text-[18px] md:text-[48px] font-black">
                {latestItem.name}
              </div>

              <p className="text-[15px] tracking-tight font-normal text-justify">
                {stripHtmlFromString(latestItem.description).substring(0, 125)}
                ...{" "}
                <a href={nftUrl} className="text-brand cursor-pointer">
                  View More
                </a>{" "}
              </p>
            </div>
          </div>
          <div>
            <WhatsInsideComponent
              className="w-[300px]"
              isPreview={true}
              actionText={`Enter`}
              whatsInside={latestItem.whatsInside}
              actionHref={nftUrl}
            />
          </div>
        </div>
        {showBackground && <PatternBackground className="flex md:hidden" />}
      </div>
    )
  );
};
